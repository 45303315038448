// React imports
import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from '@apollo/client';

// Context and API imports
import api from '@/global-components/api'

// Type imports
import { User, ProductType, Organisation, ProductGroup, ProductsViewType } from '@/global-components/types'

// Component imports
import { useToast } from "@/global-components/components/ui/use-toast";
import ProductPreview from './ProductPreview';
import Combobox from '@/global-components/components/combobox/Combobox';
import { filter, groupBy, uniq } from 'lodash';
import GroupPreview from './GroupPreview';
import InfoTooltip from '@/global-components/components/bw/InfoTooltip';
import useUserStore from '@/context/useUserStore';
import ProductsList from '@/components/ProductsList';
import useProductStore from '@/context/useProductStore';
import PaginationControls from '@/global-components/components/ui/paginationControls';
import { Building, Frown, Info, Loader } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/global-components/components/ui/popover';


type ProductStatus = 'unapproved' | 'approved' | 'production';

/* LOVELY OLD HYPERSUPER WORK IN HERE */
interface OrganisationsProductsProps {
  groupByProjects?: boolean;
  productsViewType?: ProductsViewType
  organisations?: Organisation[]
}

const OrganisationsProducts = ({groupByProjects=false, productsViewType='GRID', organisations=[]}: OrganisationsProductsProps) => {
  const { user } = useUserStore()
  const { toast } = useToast()
  const { productsSharedWithYou, setProductsSharedWithYou } = useProductStore()

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reload = queryParams.get('reload');

  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [groupsOfSharedProducts, setGroupsOfSharedProducts] = useState<ProductGroup[]>([])
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [productsOfGroups, setProductsOfGroups] = useState<ProductType[]>([])

  const [gridColumns, setGridColumns] = useState<string>('grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8');
  useEffect(() => {
    setIsMounted(true)
    setGridColumns('grid-cols-' + Math.round(window.innerWidth / 300));
    const handleResize = () => {
      setGridColumns('grid-cols-' + Math.round(window.innerWidth / 300));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      setIsMounted(false)
    };
  }, []);

  const [sharedProductsPage, setSharedProductsPage] = useState<number>(1)
  const [sharedProductsTotalPages, setSharedProductsTotalPages] = useState<number>(1)
  const [filteredOrganisationId, setFilteredOrganisationId] = useState<string>('')
  const [filterByOrgIds, setFilterByOrgIds] = useState<string[]>([])

  const getOrganisationsProducts = useQuery(api.products.queries.GET_USERS_ORGANISATIONS_PRODUCTS, {
    pollInterval: 10000,
    fetchPolicy: 'cache-first',
		nextFetchPolicy: 'cache-only',
    variables: {
      userId: user?.userId,
      page: sharedProductsPage,
      pageSize: 20,
      organisationIds: filterByOrgIds
    },
    skip: !user || !isMounted
  })

  useEffect(() => {
    // getOrganisationsProducts.refetch()
    setFilterByOrgIds(filteredOrganisationId !== '' ? [filteredOrganisationId] : [])
  }, [filteredOrganisationId])

  useEffect(() => {
    getOrganisationsProducts.refetch()
  }, [sharedProductsPage])

  const getOrganisationsGroupsWithProducts = useQuery(api.products.queries.GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS, {
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    skip: !isMounted || !groupByProjects
  })


  const sortProductsByStatus = (a: any, b: any): number => {
    const order: { [key: string]: number } = {
      'production': 1,
      'approved': 2,
      'unapproved': 3,
    };
  
    return order[a.status.toLowerCase()] - order[b.status.toLowerCase()];
  };

  const productPartOfFilteredOrg = (product:ProductType): boolean => {
    if (filteredOrganisationId === '') {
      return true
    } else {
      return product.organisations?.find(org => org.organisationId === filteredOrganisationId) ? true: false
    }
  }

  const goToProduct = (productRef: string) => {
    navigate('/product/' + productRef);
  }

  useEffect(() => {
    if (getOrganisationsProducts.data?.productsForOrganisations)  {
      console.log('org prods: ', getOrganisationsProducts.data?.productsForOrganisations)
      setSharedProductsTotalPages(getOrganisationsProducts.data?.productsForOrganisations.totalPages)

      const sortedProducts: ProductType[] = [...getOrganisationsProducts.data?.productsForOrganisations.products]
      setProductsSharedWithYou(sortedProducts);
      const uniqueGroupsOfSharedProducts: ProductGroup[] = []
      for (const product of sortedProducts) {
        if (!uniqueGroupsOfSharedProducts.find(group => group.groupId === product.groupId.groupId)) {
          uniqueGroupsOfSharedProducts.push(product.groupId)
        }
      }
      setGroupsOfSharedProducts(uniqueGroupsOfSharedProducts)
      // setGroupsOfSharedProducts(
      //   sortedProducts.map(product => product.groupId)
      //   .filter((group: ProductGroup, index: number, self: ProductGroup[]) =>
      //     index === self.findIndex(g => g.groupId === group.groupId)
      //   )
      // )
    }
  }, [getOrganisationsProducts.data])

  useEffect(() => {
    if (getOrganisationsGroupsWithProducts.data?.groupsOfOrganisationsWithProducts) {
      setGroups(getOrganisationsGroupsWithProducts.data.groupsOfOrganisationsWithProducts.groups)
      setProductsOfGroups(getOrganisationsGroupsWithProducts.data.groupsOfOrganisationsWithProducts.products)
    }
  }, [getOrganisationsGroupsWithProducts.data])


  // if (productsSharedWithYou.length === 0) {
  //   return null
  // }


  return (
    <div className='mt-24'>
      <div className='flex justify-between mb-4'>
        <div className='text-bw-green flex items-center gap-2 z-50'>
          <h1 className='flex items-center gap-2 text-2xl whitespace-nowrap'>
            <div>
              Shared with you {filteredOrganisationId === '' ? '' : ' via ' + organisations.find(org => org.organisationId === filteredOrganisationId)?.name}
            </div>
            <Popover>
              <PopoverTrigger asChild>
                <div className='flex items-center gap-1 w-full h-full truncate cursor-pointer'>
                  <Info className='h-4 w-4 opacity-30' />
                </div>
              </PopoverTrigger>
              <PopoverContent side='top' className="max-w-[350px] flex gap-2 p-4 px-5 border border-bw-green/10 outline-none bg-white text-bw-green text-sm rounded-md shadow-xl left-0 relative leading-tight">
                <div className='flex flex-col gap-0.5'>
                  <div className='text-lg font-medium flex items-center gap-1'>
                    <Building className='h-4 w-4'/>
                    Sharing products and projects
                  </div>
                  Any product or project shared with an organisation is accessible to all its members.
                </div>
              </PopoverContent>
            </Popover>
          </h1>
          {groupByProjects && <InfoTooltip>To see all shared products, please switch off <b>View Projects</b></InfoTooltip>}
        </div>
        {productsSharedWithYou.length > 0 &&
          <div className='absolute left-1/2 transform -translate-x-1/2 flex-shrink-0'>
            <PaginationControls page={sharedProductsPage} loading={getOrganisationsProducts.loading} onChange={setSharedProductsPage} totalPages={sharedProductsTotalPages} className='' />
          </div>
        }
        <Combobox
          title='Filter by organisation'
          items={organisations.map(org => { return {
            value: org.organisationId,
            name: org.name
          }})}
          value={filteredOrganisationId}  
          capitalise
          wide
          selectCallback={(newValue: any) => setFilteredOrganisationId(newValue)} />
      </div>
      {productsViewType === 'GRID' 
        ? groupByProjects ? 
          <div className={gridColumns ? 'all-products grid ' + gridColumns + ' gap-4' : 'all-products grid grid-cols-5 gap-4'}>
            {groups.map((group: ProductGroup, index: number) => (
              <GroupPreview
                group={group}
                key={index}
                products={productsOfGroups.filter(product => product.groupId.groupId === group.groupId)}
              />
            ))}
            {/* {groupsOfSharedProducts.map((group: ProductGroup, index: number) => (
                <GroupPreview
                  group={group}
                  products={products.filter(product => product.groupId.groupId === group.groupId)}
                  noAccess
                />
              ))
            } */}
          </div>
          :
          <div className={`all-products grid 
            ${gridColumns ? 'gap-4 ' + gridColumns : 'grid-cols-5 gap-4'}
            ${getOrganisationsProducts.loading && 'opacity-40 pointer-events-none'}`}>
            {/* {getOrganisationsProducts.loading && <div className='absolute z-10 -top-4 left-0 w-full flex justify-center'>
              <div className='relative -left-0 w-min whitespace-nowrap shadow-2xl py-2 px-3 gap-2 rounded-md bg-white/90 backdrop-blur-lg flex items-center justify-center'>
                <Loader className='h-4 w-4 animate-spin' /> <div className='animate-pulse'>Loading new data</div>
              </div>
            </div>} */}
            {productsSharedWithYou.map((product: any, index: number) => (
              <ProductPreview 
                key={index} 
                product={product} 
                navigate={navigate} 
                index={index}
                disabled={getOrganisationsProducts.loading}
                hideDelete
                showOrg 
              />
            ))}
          </div>
        : <ProductsList
            filteredProducts={productsSharedWithYou} 
            productDeleted={() => null}
            goToProduct={goToProduct}
            loading={getOrganisationsProducts.loading}
            user={user}
        />
      }
      {(!productsSharedWithYou.length && getOrganisationsProducts.loading) &&
        <div className='flex items-center justify-center gap-2 mb-24 mt-8'>
          <Loader className='h-4 w-4 animate-spin' />
        </div>
      }

      {(productsSharedWithYou.length === 0 && !getOrganisationsProducts.loading) &&
        (filteredOrganisationId !== ''
          ? <div className='flex items-center justify-center gap-2 mb-24 mt-8'>
              Sorry, but no one has shared products into the<b>{organisations.find(org => org.organisationId === filteredOrganisationId)?.name}</b>organisation so far.
            </div>
          : <div className='flex items-center justify-center gap-2 mb-24 mt-8'>
              There's nothing to show here.
            </div>
        )
      }
    </div>
  )
}

export default OrganisationsProducts